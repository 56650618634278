import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import loader from '../../assets/images/loader/Rolling-1s-169px.gif';
import '../../assets/css/Loader.css';


export const Loader = ({width, isLoading}) => {
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, 5000);
  }, []);
  const load = async () => {
    const url = new URL(window.location.href);
    if (url.pathname.toLowerCase().indexOf('/servicer-dashboard') !== -1 && 'serviceWorker' in navigator && navigator.serviceWorker.getRegistrations) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      const unregisterPromises = registrations.map((registration) => registration.unregister());
      const allCaches = await caches.keys();
      const cacheDeletionPromises = allCaches.map((cache) => caches.delete(cache));
      await Promise.all([...unregisterPromises, ...cacheDeletionPromises]);
      window.location.reload();
    } else {
      window.location.reload();
    }
  };
  return (
    isLoading && (
      <div>
        <img src={loader} alt="Loader" className="vertical-center mb-5" style={{width: `${width}%`}} />
        <button className={`btn btn-link vertical-center ${isShown ? `top-up` : `top-down`}`} type="button" onClick={load}>
          Click here to refresh.
        </button>
      </div>
    )
  );
};

Loader.propTypes = {
  width: PropTypes.number,
  isLoading: PropTypes.bool,
};
Loader.defaultProps = {
  width: 10,
  isLoading: false,
};

import initialState from './InitialState';
import {SET_LOCALE_WORDS, SET_TRANSLATIONS, SET_LOCALE_SINGLE_WORD} from "../actions/ActionTypes";

const TranslationsReducer = (state = initialState.translations, action) => {
  switch (action.type) {
    case SET_TRANSLATIONS: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case SET_LOCALE_WORDS: {
      return {
        ...state,
        localeWords: action.localeWords,
      };
    }
    case SET_LOCALE_SINGLE_WORD: {
      const data = [...state.localeWords];
      const newData = data.map((obj) => {
        if (obj.EnglishTextID === action.word.EnglishTextID) {
          return action.word;
        }
        return obj;
      });
      return {
        ...state,
        localeWords: [...newData],
      };
    }
    default: {
      return state;
    }
  }
};
export default TranslationsReducer;

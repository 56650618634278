import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { AppRoute } from './app/route/Routes';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/Main.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/billing-layout.css';
import './assets/css/match-payroll.css';
import Root from "./Root";
import 'video-react/dist/video-react.css';

ReactDOM.render(
  <Root>
    <I18nextProvider i18n={i18n}>
      <AppRoute />
    </I18nextProvider>
  </Root>,
  document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

export default {
  auth: {
    isAuthenticated: false,
    user: {},
    muser: {},
    permission: {},
    mtoken: '',
    localeID: '',
    iuser: {},
  },
  integrations: [],
  mapProperties: {
    properties: {},
    customers: [],
  },
  filters: {
    propertyTags: [],
    region: [],
    owner: [],
    staffTag: [],
    department: [],
    properties: [],
    staffs: [],
  },
  mapPayroll: {
    payroll: {},
    wages: [],
  },
  mapStaffs: {
    staffs: {},
    employees: [],
  },
  mapTaskRules: {
    taskRule: {},
    items: [],
  },
  billingApprove: {
    billableTasks: {},
  },
  zapierApprove: {
    zapierTasks: {},
  },
  timeTracking: {
    timeClock: {},
  },
  syncLogs: {
    logs: {},
    logDetails: {},
  },
  mobileDashBoard: {
    tasks: [],
    notes: [],
    isMore: true,
  },
  mobileDashboardTabs: {
    logs: {},
    logCache: {},
    bookings: {},
    bookingCache: {},
    info: {},
    infoCache: {},
    imgs: {},
    imgsCache: {},
    assignments: [],
    manage: {},
    assignmentsCache: [],
    manageCache: {},
    issue: {},
    stateImgPreview: [],
    taskNote: {},
    imgPreview: {},
    taskCurrentForm: {},
  },
  unscheduledTask: {
    properties: [],
    taskDetails: {},
    property: {},
    propertyCache: {},
  },
  translations: {
    locale: [],
    localeWords: [],
  },
};

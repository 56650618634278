import {
  SET_CURRENT_USER,
  SET_USER_PERMISSION,
  AUTHENTICATE,
  SET_CURRENT_MOBILE_USER,
  SET_CURRENT_MOBILE_TOKEN,
  SET_CURRENT_USER_LOCALE,
  SET_CURRENT_ISSUE_USER,
} from "./ActionTypes";
import AuthService from "../../services/AuthService";
import {
  setToken,
  setPermission,
  setUserDetails,
  clearToken,
  setLoggedInStaff,
  clearMobileToken,
  clearIssueToken,
} from "../../utils/TokenManager";
import { VRSConst } from "../../app/Constants";

/**
 * It dispatches the login reducer and updates the state of current user.
 * @param authData
 * @return {{type, authData: *}}
 */
export function setCurrentUser(authData) {
  return {
    type: SET_CURRENT_USER,
    authData,
  };
}

/**
 * It dispatches the login reducer and updates user permission.
 * @param permissionData
 * @return {{type, permissionData: *}}
 */
export function setUserPermission(permissionData) {
  return {
    type: SET_USER_PERMISSION,
    permissionData,
  };
}

export function setUserLocale(localeData) {
  return {
    type: SET_CURRENT_USER_LOCALE,
    localeData,
  };
}
/**
 * Call reducer to check if the user is already authenticated
 * @param auth
 * @returns {{auth: *, type: *}}
 */
function authenticateSuccess(auth) {
  return {
    type: AUTHENTICATE,
    auth,
  };
}

/**
 * Call reducer to set mobile user
 * @param auth
 * @returns {{auth: *, type: *}}
 */
const setCurrentMobileUser = (auth) => ({
  type: SET_CURRENT_MOBILE_USER,
  auth,
});
const setCurrentIssueUser = (iauth) => ({
  type: SET_CURRENT_ISSUE_USER,
  iauth,
});
const setCurrentMobileToken = (token) => ({
  type: SET_CURRENT_MOBILE_TOKEN,
  token,
});
/**
 * This method calls Login API and validates the user.
 * If user name password is correct .It dispatches the login action
 */
export const authenticate = async (dispatch) => {
  try {
    const authResponse = await AuthService.doAuthenticate();
    if (authResponse.data.ReasonCode === 0) {
      const tokenResponse = await clearToken();
      if (tokenResponse) {
        // set data to cookie storage
        await setToken(authResponse.data.AccessToken, "JWT");
        await setUserDetails(
          {
            ...authResponse.data.UserDetails,
            LocaleID: authResponse.data.LocaleID,
          },
          "u_details"
        );
        setLoggedInStaff(
          authResponse.data.LoggedInStaffID,
          authResponse.data.LoggedInServicerPassword
        );
        await setPermission(authResponse.data.Permissions, "permission");
        // Dispatch action to set user details
        dispatch(
          setCurrentUser({
            ...authResponse.data.UserDetails,
            LocaleID: authResponse.data.LocaleID,
          })
        );
        dispatch(setUserPermission(authResponse.data.Permissions));
        dispatch(setUserLocale(authResponse.data.LocaleID));
      }
      return true;
    }
    if (authResponse.data.ReasonCode === 1004) {
      const token = await clearToken();
      console.log(token);
      throw authResponse;
    }
  } catch (error) {
    throw error.data;
  }
  return false;
};

/**
 *
 * To logout a user
 * @returns {Promise<void>}
 */

export const logout = async () => {
  await clearToken();
  window.location.replace(`${VRSConst.menuRedirectedURL}/logout`);
  return true;
};

/**
 * Action to check if the user is updated
 * @param auth
 * @param dispatch
 */
export const isAuthenticated = (auth, dispatch) => {
  dispatch(authenticateSuccess(auth));
};

export const tokenRefresh = async () => {
  try {
    const authResponse = await AuthService.doRefresh();
    if (authResponse.data.ReasonCode === 0) {
      // await deleteToken();
      setToken(authResponse.data.Token, "JWT");
    }
    return true;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * This method calls Login API and validates the mobile dashboard user.
 * If user name password is correct .It dispatches the login action
 */
export const mobileAuthenticate = async (userData, dispatch) => {
  try {
    const authResponse = await AuthService.doMobileAuthenticate(userData);
    if (authResponse.status === 200 || authResponse.status === "OK") {
      const tokenResponse = await clearMobileToken();
      if (tokenResponse) {
        // set data to cookie storage
        await setToken(authResponse.data.AccessToken, "MJWT");
        await setUserDetails(authResponse.data.Details, "Mdetails");
        await setPermission(authResponse.data.MobilePermissions, "Mpermission");
        // Dispatch action to set user details
        dispatch(setCurrentMobileUser(authResponse.data.Details));
        dispatch(setCurrentMobileToken(authResponse.data.AccessToken));
      }
    }
    return true;
  } catch (error) {
    await clearMobileToken();
    throw error.response ? error.response.data : error;
  }
};

export const issueAuthenticate = async (userData, dispatch) => {
  try {
    const authResponse = await AuthService.doIssueAuthenticate(userData);
    if (authResponse.status === 200 || authResponse.status === "OK") {
      const tokenResponse = await clearIssueToken();
      if (tokenResponse) {
        // set data to cookie storage
        await setToken(authResponse.data.AccessToken, "IJWT");
        await setUserDetails(authResponse.data.Details, "Idetails");
        dispatch(setCurrentIssueUser(authResponse.data.Details));
      }
    }
    return true;
  } catch (error) {
    await clearIssueToken();
    throw error.response ? error.response.data : error;
  }
};

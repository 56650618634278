import initialState from './InitialState';
import {
  SET_ALL_PROPERTY_TAGS,
  SET_ALL_REGIONS,
  SET_ALL_OWNERS,
  SET_ALL_DEPARTMENTS,
  SET_ALL_STAFF_TAGS,
  SET_ALL_PROPERTIES,
  SET_ALL_STAFFS,
} from '../actions/ActionTypes';

const FiltersReducer = (state = initialState.filters, action) => {
  switch (action.type) {
    case SET_ALL_PROPERTY_TAGS: {
      return {
        ...state,
        propertyTags: action.propertyTagDetails,
      };
    }
    case SET_ALL_REGIONS: {
      return {
        ...state,
        region: action.regionDetails,
      };
    }
    case SET_ALL_OWNERS: {
      return {
        ...state,
        owner: action.ownerDetails,
      };
    }
    case SET_ALL_STAFF_TAGS: {
      return {
        ...state,
        staffTag: action.staffTagDetails,
      };
    }
    case SET_ALL_DEPARTMENTS: {
      return {
        ...state,
        department: action.departmentDetails,
      };
    }
    case SET_ALL_PROPERTIES: {
      return {
        ...state,
        properties: action.propertiesDetails,
      };
    }
    case SET_ALL_STAFFS: {
      return {
        ...state,
        staffs: action.staffsDetails,
      };
    }
    default:
      return state;
  }
};
export default FiltersReducer;

import Axios from 'axios';
import * as moment from 'moment';
import * as timezoneMoment from 'moment-timezone';
import {getToken} from './TokenManager';
import {VRSConst} from "../app/Constants";

const dynamicLangImport = (locale) => {
  let localization = null;
  switch (locale.toLowerCase().substring(0, 3)) {
    case 'spa': {
      localization = require('moment/locale/es');
      break;
    }
    case 'fre': {
      localization = require('moment/locale/fr');
      break;
    }
    case 'jap': {
      localization = require('moment/locale/ja');
      break;
    }
    case 'por': {
      localization = require('moment/locale/pt');
      break;
    }
    case 'ger': {
      localization = require('moment/locale/da');
      break;
    }
    default: {
      localization = require('moment/locale/en-au');
    }
  }
  return localization;
};
/**
 * Created a common axios instance
 */
const axiosInstance = Axios.create({
  baseURL: VRSConst.getUrl(),
  headers: {
    'Content-Type': 'application/json',
    Authorization: `VRS ${getToken()}`,
  },
});

/**
 * Created a common mobile axios instance
 */
const mobileAxiosInstance = Axios.create({
  baseURL: VRSConst.getUrl(),
  headers: {
    'Content-Type': 'application/json',
    Authorization: `VRS ${getToken('MJWT')}`,
  },
});

/**
 * Converts the data to base-64
 * @param data
 */
const convertJsonBase64 = (data) => btoa(JSON.stringify(data));

/**
 * get data before 2 days of current date
 * @returns {string}
 */
const get2DaysBeforeDate = () => {
  const date = new Date(Date.now());
  return `${date.getMonth()}/${(date.getDate() - 2)}/${date.getFullYear()}`;
};

/**
 * get  current date
 * @returns {string}
 */
const getCurrentDate = () => {
  const date = new Date(Date.now());
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const changeDateFormat = (dateData, format = 'YYYY-MM-DD') => moment(dateData).format(format);

const changeDateToLocale = (dateData, locale = "en", format = 'YYYY-MM-DD') => moment(dateData).locale(locale, dynamicLangImport(locale)).format(format);

const subtractDate = (number, type, format) => moment().subtract(number, type).format(format);

const changeTimeZone = (date, timezone = 'US/Pacific', format = 'YYYY-MM-DD') =>{
  return timezoneMoment.tz(date, timezone).format(format);
}

// eslint-disable-next-line no-useless-escape
const changeDateFormatLocale = (date, locale = 'en-US', dateStyle = 'medium') => {
  let option = {};
  switch (dateStyle) {
    case "short": {
      option = {month: 'numeric', day: 'numeric', year: '2-digit'};
      break;
    }
    case "full": {
      option = {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'};
      break;
    }
    case "shortdt": {
      option = {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      break;
    }
    case "weekdaydt": {
      option = {weekday: 'long', month: 'numeric', day: 'numeric', year: '2-digit'};
      break;
    }
    default: {
      option = {month: 'numeric', day: 'numeric', year: 'numeric'};
    }
  }
  const localeValue = locale || 'en-US';
  // eslint-disable-next-line no-useless-escape
  // const oldDate = new Date(date.toLocaleString().substr(0, 10).replace(/-/g, '\/')).toLocaleString(locale, {dateStyle});
  // eslint-disable-next-line no-useless-escape
  const newDate = new Intl.DateTimeFormat(localeValue, option).format(new Date(date.toLocaleString().substr(0, 10).replace(/-/g, '\/')));
  return newDate;
};

const changeImageDateFormat = (locale = 'en-us') => {
  const option = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  // eslint-disable-next-line no-useless-escape
  // const oldDate = new Date(date.toLocaleString().substr(0, 10).replace(/-/g, '\/')).toLocaleString(locale, {dateStyle});
  // eslint-disable-next-line no-useless-escape
  const newDate = new Intl.DateTimeFormat(locale, option).format(new Date());
  return newDate;
};
const removeParentLoader = () => {
  const loader = document.getElementById("parentLoader");
  if (loader) {
    loader.remove();
  }
};
const getDeviceTimezone = () => {
  return {
    deviceDate: new Date().toString(),
    deviceTimeZone: moment.tz.guess(),
  };
};

export {
  axiosInstance,
  mobileAxiosInstance,
  convertJsonBase64,
  get2DaysBeforeDate,
  getCurrentDate,
  changeDateFormat,
  subtractDate,
  changeDateToLocale,
  changeTimeZone,
  changeDateFormatLocale,
  changeImageDateFormat,
  removeParentLoader,
  getDeviceTimezone,
};

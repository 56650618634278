import initialState from './InitialState';
import {
  SET_ALL_SYNC_LOGS,
  SET_ALL_SYNC_LOGS_DETAILS,
  DELETE_SYNC_LOGS_DETAILS,
} from '../actions/ActionTypes';

const SyncLogsReducer = (state = initialState.syncLogs, action) => {
  switch (action.type) {
    case SET_ALL_SYNC_LOGS: {
      return {
        ...state,
        logs: action.logDetails,
      };
    }
    case SET_ALL_SYNC_LOGS_DETAILS: {
      return {
        ...state,
        logDetails: action.allLogDetails,
      };
    }
    case DELETE_SYNC_LOGS_DETAILS: {
      return {
        ...state,
        logDetails: [],
      };
    }
    default:
      return state;
  }
};
export default SyncLogsReducer;

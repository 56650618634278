import React from "react";
import StackTrace from "stacktrace-js";
import { toast } from "react-toastify";
import { VRSConst } from "../app/Constants";
import { triggerStageEmail } from "../redux/actions/MobileDashboardAction";
import { HandelError } from "../component/common/HandleError";
import { isOnline } from "./offlineUtils";
import callbackimg from "../assets/images/loader/callbackimgupdated.jpeg";
import localForage from 'localforage';

const checkForCookieEnabled = () => {
    if (navigator.cookieEnabled) return true;
    document.cookie = "cookietest=1";
    const ret = document.cookie.indexOf("cookietest=") !== -1;
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
};
/**
 * Splits 1 array into 2 pieces based on property value and returns the object
 * @param array
 * @param property
 * @returns {*}
 */
export const splitArrayBasedOnProperty = (array, property) =>
    array.reduce((memo, x) => {
        if (!memo[x[property]]) {
            memo[x[property]] = [];
        }
        memo[x[property]].push(x);
        return memo;
    }, {});

/**
 * Set the value to localstorage
 * @param key
 * @param value
 */
export const storeToLocalStorage = (key, value) => {
    checkForCookieEnabled() && localStorage.setItem(key, value);
};

/**
 * Set the value to localstorage
 * @param key
 * @param value
 */
export const storeToLocalForage = (key, value) => {
    checkForCookieEnabled() && localForage.setItem(key, value);
};

/**
 * Get data from localstorage
 * @param key
 * @returns {string}
 */
export const getFromLocalStorage = (key) =>
    checkForCookieEnabled() ? localStorage.getItem(key) : JSON.stringify({});

/**
 * Set the value to localstorage
 * @param key
 * @param value
 */
export const getFromLocalForage = (key) =>
    checkForCookieEnabled() ? localForage.getItem(key) : null;

export const removeFromLocalForage = (key) =>
    checkForCookieEnabled() ? localForage.removeItem(key): null;

export const getKeysFromLocalForage = () =>
    checkForCookieEnabled() ? localForage.keys() : null;



/**
 * remove the key from localstorage
 * @param key
 */
export const deleteFromLocalStorage = (key) => {
    localStorage.removeItem(key);
};

/**
 * remove the key from localstorage
 * @param key
 */
export const deleteFromLocalForage = (key) => {
    localForage.removeItem(key);
};

/**
 * undo selected items
 * @param data
 * @returns {*}
 */
export const undoSelected = (data) =>
    data.map((el) => {
        const o = { ...el };
        o.Status = "prevStatus" in o ? o.prevStatus : o.Status;
        o.checked = false;
        return o;
    });
/**
 * changes the status and keeps the previous status
 * @param data
 * @param item
 * @param key
 * @param status
 * @returns {*}
 */
export const changeStatus = (data, item, status, key) =>
    data.map((el) => {
        if (el[key] === item[key]) {
            const o = { ...el };
            o.prevStatus = o.Status;
            o.Status = status;
            o.isActive = false;
            return o;
        }
        const a = { ...el };
        a.isActive = false;
        return a;
    });
/**
 * check single item
 * @param data
 * @param item
 * @param key
 * @returns {*}
 */
export const checkItem = (data, item, key) =>
    data.map((el) => {
        if (el[key] === item[key]) {
            const o = { ...el };
            o.checked = !o.checked;
            return o;
        }
        return el;
    });
/**
 * check all items
 * @param data
 * @param checkALl
 * @returns {*}
 */
export const checkAllItems = (data, checkALl) =>
    data.map((el) => {
        const o = { ...el };
        o.prevStatus = o.Status;
        o.checked = checkALl;
        return o;
    });

/**
 * convert seconds to hr:min:sec
 * @param scnds
 * @returns {string}
 */
export const secondsToDHM = (scnds) => {
    let seconds = parseInt(scnds, 10);

    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;

    const mnts = Math.floor(seconds / 60);
    seconds -= mnts * 60;
    // adding days to hoursa
    if (days) {
        hrs = days * 24 + hrs;
    }
    return `${hrs.toString().padStart(2, "0")}:${mnts
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
export const handleToaster = (e, type = "E", autoclose = false) => {
    try {
        if (VRSConst.toastList.size >= VRSConst.MAX_TOAST) {
            toast.dismiss();
            VRSConst.toastList.clear();
        }
        if (VRSConst.toastList.size < VRSConst.MAX_TOAST) {
            let id = "";
            if (type === "S") {
                id = toast.success(e, {
                    autoClose: autoclose,
                    onClose: () => VRSConst.toastList.delete(id),
                });
            } else if (type === "I") {
                id = toast.info(e, {
                    autoClose: false,
                    className: "custom-toast",
                    onClose: () => VRSConst.toastList.delete(id),
                });
            } else {
                // alert(JSON.stringify(e));
                id = toast.error(<HandelError error={e} />, {
                    autoClose: 2000,
                    // onClose: () => VRSConst.toastList.delete(id),
                });
            }
            VRSConst.toastList.add(id);
        }
    } catch (error) {
        // alert(JSON.stringify(error));
    }
};
export const getMyLocation = async () => {
    let timeoutId;
    const timeoutPromise = new Promise((resolve) => {
        // console.log("Timeout for location call started ...")
        let latitude = null;
        let longitude = null;
        let accuracy = null;
        timeoutId = setTimeout(() => {
            // console.log("Giving up on location call.")
            resolve(
                {
                    latitude,
                    longitude,
                    accuracy,
                }
            );
        }, 10000);
    })

    let locationPromise =new Promise((resolve) => {
        // console.log("Attempting to get location ...")

        let location = null;
        let latitude = null;
        let longitude = null;
        let accuracy = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation;
        }
        if (location) {
            location.getCurrentPosition(
                (position) => {
                    latitude = position.coords.latitude;
                    longitude = position.coords.longitude;
                    accuracy = position.coords.accuracy;
                    // console.log("Got location !!! " + latitude)
                    resolve({
                        latitude,
                        longitude,
                        accuracy,
                    });
                },
                (error) => {
                    console.log(error, "location error");
                    // checking the error type
                    // if (error.code === error.PERMISSION_DENIED || error.code === error.POSITION_UNAVAILABLE || error.code === error.TIMEOUT) {
                    //   // pop up dialog asking for location
                    //   resolve({
                    //     latitude,
                    //     longitude,
                    //     accuracy,
                    //   });
                    // }
                    resolve({
                        latitude,
                        longitude,
                        accuracy,
                    });
                },
                {
                    enableHighAccuracy: false,
                    maximumAge: 0,
                }
            );
        } else {
            resolve({
                latitude,
                longitude,
                accuracy,
            });
        }
    });
    var locationResponse = await Promise.race([locationPromise, timeoutPromise])
    return locationResponse;
};
export const dataURLToBlob = (dataURL) => {
    const BASE64_MARKER = ";base64,";
    let parts;
    let contentType;
    let raw;
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        parts = dataURL.split(",");
        contentType = parts[0].split(":")[1];
        raw = parts[1];
        return new Blob([raw], { type: contentType });
    }
    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(":")[1];
    raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
};

export const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
};

export const errorEmail = (error, elseBlock = 0) => {
    StackTrace.fromError(error)
        .then((err) => {
            const request = {
                Subject: `Frontend error on ${window.location.href}`,
                Error: `ErrorDetails - ${error} \n
            User: ${getFromLocalStorage("MobileDashBoardCred")} \n
            User error: ${
                    !elseBlock ? `Something went wrong` : `else block error`
                } \n
            userAgent: ${window.navigator.userAgent} \n
            appVersion: ${VRSConst.reactAppVersion} \n
            Offline: ${!isOnline()} \n
            ErrorBoundry- ${err[0]}`,
                Source: "FE",
            };
            triggerStageEmail(request);
            // console.log(request);
            // this.setState({stackEror: err[0]});
        })
        .catch((err2) => {
            console.log(err2);
        });
};

// replace image on image load error
export const handleImgLoadError = (e) => {
    if (navigator.onLine) {
        e.target.parentNode.style.display = "none";
    } else {
        e.target.src = callbackimg;
    }
    // e.target.previousElementSibling.style.display = 'none';
    // if(e.target.nextElementSibling.type === 'button') {
    //   e.target.nextElementSibling.style.display = 'none';
    //   e.target.nextElementSibling.nextElementSibling.style.display = 'none';
    // }
};

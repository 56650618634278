import { axiosInstance, convertJsonBase64 } from "../utils/Client";
import Axios from "axios";
import { VRSConst } from "../app/Constants";

export default class AuthService {
  /**
   * This interacts with backend to validate token
   */
  static doAuthenticate = async () => {
    let Response = null;
    console.log("about to validate ...")
    Response = await axiosInstance.post("/oauth/validate");
    return Response;
    // for testing purpose
    // const Response = {
    //   data: {
    //     ReasonCode: 0,
    //     ReasonText: "Not Authorized",
    //     LoggedInStaffID: 1,
    //     AccessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImV4cCI6IjM2MDAifQ.eyJDdXN0b21lcklEIjoxLCJMb2dnZWRJblN0YWZmSUQiOjEsIkN1c3RvbWVyTmFtZSI6IkFtaXRhYmggUGF0bmFpayIsIkNyZWF0ZURhdGVUaW1lIjoiMjAxOTA5MjQwNjExIn0.lFQm5nBxcTu-ex0NqT2L5O3_c2sgUiqK6Yhy_GynQVo",
    //     UserDetails: {
    //       CustomerId: "1234eod",
    //       CustomerName: "Sobhan",
    //     },
    //     Permissions: {
    //       AllowAdminAccess: 0,
    //       AllowManage: 0,
    //       AllowReports: 1,
    //       AllowSetupAccess: 1,
    //       AllowAccountAccess: 0,
    //       AllowIssuesAccess: 0,
    //       AllowQuickReports: 1,
    //       AllowScheduleAccess: 0,
    //       AllowMasterCalendar: 0,
    //       TimeTracking: 0,
    //       RegionGroup: 1,
    //       PropertyGroup: 1,
    //       PiecePay: 0,
    //       ICalAddOn: 1,
    //     },
    //   },
    // };
    // return Response.data;
  };

  /**
   * Call API to refresh the JWT access token
   * @returns {Promise<AxiosResponse<T>>}
   */
  static doRefresh = async () => {
    const Response = await axiosInstance.post("/oauth/refresh");
    return Response;
  };

  static doMobileAuthenticate = async (requestData) => {
    const base64Data = convertJsonBase64(requestData);
    let Response = null;
    console.log(VRSConst.getUrl());
    console.log(`${VRSConst.getUrl()}/pwa/authenticate?data=${base64Data}`);
    Response = await Axios.get(
      `${VRSConst.getUrl()}/pwa/authenticate?data=${base64Data}`
    );
    return Response;
  };

  static doIssueAuthenticate = async (requestData) => {
    const base64Data = convertJsonBase64(requestData);
    let Response = null;
    Response = await Axios.get(
      `${VRSConst.getUrl()}/pwa/authenticate/issues?data=${base64Data}`
    );
    return Response;
  };

  static checkAppVersion = async (requestData) => {
    let Response = null;
    Response = await Axios.get(`${VRSConst.url}/v1/appVersion/${requestData}`);
    return Response;
  };
}

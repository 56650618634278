import i18n from 'i18next';
// import axios from 'axios';
import LanguageDetector from 'i18next-browser-languagedetector';
// import XHR from "i18next-xhr-backend";
import Backend from 'i18next-http-backend';
// import translationEng from "./locales/English";
// import translationFr from "./locales/French";
// import translationSp from "./locales/Spanish";
// import translationJp from "./locales/Japanese";
// import translationpr from "./locales/Portuguese";
// const baseUrl = 'https://testapp-demo.s3.us-east-2.amazonaws.com/locales';
// const langData = async (base, lang) => {
//   const langContent = await axios.get(`${base}/${lang}.json`);
//   return langContent.data;
// };

const options = () => {
    const op = {
    // debug: true, // used for debugging propos
    lng: "en-us".toLowerCase(),
    initImmediate: false,
    fallbackLng: "en-us", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
    escapeValue: false, // react already safes from xss
  },
    backend: {
      loadPath: `https://vrscheduler.s3.us-west-2.amazonaws.com/locales/${`{{lng}}`.toLowerCase()}.json`,
    },
    ns: ["translations"],
    defaultNS: "translations",
};
    return op ;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init(options());

export default i18n;

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  List,
  ListItem,
  Link,
  Icon,
  Button,
  Box,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";

const useStyles = makeStyles({
  padding0: {
    padding: "0 0 !important",
  },
  iconfont: {
    fontSize: "21px",
    color: "#475C76",
  },
  btnContent: {
    justifyContent: "initial",
  },
  positionUnset: {
    position: "unset",
  },
});
export const MainHeaderMenu = ({ NavList, handleNodeClick, FromMain }) => {
  const classes = useStyles();
  const userDetails = useSelector((state) => state.AuthReducer.user);
  const [closeNav, setCloseNav] = useState(true);
  const handleDropDown = () => {
    setCloseNav(!closeNav);
  };
  const printName = (ServicerName) => {
    if (ServicerName) {
      return ServicerName.match(/\b(\w)/g).join("");
    }
    return " ";
  };

  return (
    <Box className="right-block">
      <List
        className={`header-tools ${!FromMain ? "mobile" : ""} ${
          classes.positionUnset
        }`}
      >
        {NavList.filter((fliterValue) => fliterValue.isUserPart === false).map(
          (navLink) => {
            if (!navLink.isParent) {
              return (
                <ListItem className={classes.padding0}>
                  <div
                    className={`popup-holder ${
                      navLink.isSelected ? `popup-active active-drop` : ``
                    }`}
                  >
                    <Link
                      className={`tool-link popup-opener ${classes.btnContent}`}
                      href={navLink.path}
                    >
                      <span className="ico-holder">
                        <Icon
                          className={`${navLink.displayIcon} ${classes.iconfont}`}
                        />
                      </span>
                      <span className="text">{navLink.name}</span>
                    </Link>
                  </div>
                </ListItem>
              );
            }
            return (
              <ListItem
                className={`${classes.padding0} ${classes.positionUnset} `}
              >
                <div
                  className={`popup-holder ${
                    navLink.isSelected ? `popup-active active-drop` : ``
                  }`}
                >
                  <Button
                    className={`tool-link popup-opener ${classes.btnContent}`}
                    onClick={() => handleNodeClick(navLink, "mainmenu")}
                  >
                    <span className="ico-holder">
                      {navLink.displayIcon ? (
                        <Icon
                          className={`${navLink.displayIcon} ${classes.iconfont}`}
                        />
                      ) : (
                        <AssessmentOutlinedIcon className={classes.iconfont} />
                      )}
                    </span>
                    <span className="text">{navLink.name}</span>
                  </Button>
                  <div className="popup">
                    <div className="popup-header">
                      <span className="title">{navLink.name}</span>
                      <Button
                        className={`${classes.navbutton} back`}
                        onClick={() => handleNodeClick(navLink, "mainmenu")}
                      >
                        <i className="fa fa-chevron-left" aria-hidden="true" />
                      </Button>
                    </div>
                    <Box className="popup-body">
                      <List className="list">
                        {navLink.childNode.length
                          ? navLink.childNode.map((childNavList) => (
                              <ListItem className={classes.padding0}>
                                <Link href={childNavList.path}>
                                  {childNavList.name}
                                </Link>
                              </ListItem>
                            ))
                          : ""}
                      </List>
                    </Box>
                  </div>
                </div>
              </ListItem>
            );
          }
        )}
      </List>
      {FromMain ? (
        <Box className="user-details">
          <Box className="user-circle">
            <a href={window.location.href}>
              {printName(userDetails.CustomerName)}
            </a>
          </Box>
          <Box className="header-open-close">
            <Button
              className={`opener ${classes.btnContent} ${classes.padding0}`}
              onClick={handleDropDown}
            >
              {closeNav ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
            <Box className={`slide ${closeNav ? `display-none` : ``}`}>
              <List className="list">
                {NavList.filter(
                  (fliterValue) => fliterValue.isUserPart === true
                ).map((userNav) => (
                  <ListItem>
                    <Link href={userNav.path}>{userNav.name}</Link>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
MainHeaderMenu.propTypes = {
  NavList: PropTypes.instanceOf(Array).isRequired,
  handleNodeClick: PropTypes.func.isRequired,
  FromMain: PropTypes.number.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {checkModulePermission} from "../../utils/AclUtil";
import NewMainHeaderContainer from "./newHeader/NewMainHeaderContainer";

const Header = ({ NavItem, SubNavItem, userDetails }) => {
  const [NavList, setNavList] = useState([]); // Define initial header object
  const [SubNavList, setSubNavList] = useState([]); // Define initial sub header object
  /**
   * Function to check premission and create the new header object according to that
   * @param nodes
   */
  const forEachNode = (nodes) => {
    // let nodes = this.checkForPermission();
    const headerList = [];
    let id = Math.round(Math.random() * 100);
    let index = 0;
    // checks the module has permission or not
    nodes.forEach((eachList) => {
      // checks the module has permission or not
      if (checkModulePermission(eachList.module, "permission")) {
        headerList.push({
          name: eachList.name,
          module: eachList.module,
          isParent: eachList.isParent,
          childNode: [],
          isSelected: false,
          isUserPart: eachList.isUserPart || false,
          path: eachList.path,
          id,
          icon: eachList.icon,
          displayIcon: eachList.displayIcon ? eachList.displayIcon : '', 
        });
        // Check if module has child node
        if (eachList.isParent) {
          id += 1;
          // checks the child node has permission or not
          eachList.childNode.forEach((eachChild) => {
            if (checkModulePermission(eachChild.module)) {
              headerList[index].childNode.push({
                name: eachChild.name,
                module: eachList.module,
                path: eachChild.path,
                isSelected: false,
                icon: eachChild.icon,
                htmlTAG: eachChild.htmlTAG,
                displayIcon: eachList.displayIcon ? eachList.displayIcon : '',
                isUserPart: eachList.isUserPart || false,
                id,
              });
              id += 1;
            }
          });
        }
        index += 1;
        id += 1;
      }
    });
    return headerList;
  };
  /**
   * method to run when NavLink prop changes
   */
  React.useEffect(() => {
    setNavList(forEachNode(NavItem));
    setSubNavList(forEachNode(SubNavItem));
  }, [NavItem, SubNavItem]);
  /**
   * It checks and handels the each click of the navigation link
   * @param nodeValue
   * @param clickedOn
   *
   */
  const handleNodeClick = (nodeValue, clickedOn) => {
    if (clickedOn === 'submenu') {
      const data = SubNavList.map((list) => {
        if (list.id === nodeValue.id) {
          list.isSelected = !nodeValue.isSelected;
        } else {
          list.isSelected = false;
        }
        return list;
      });
      const NavData = NavList.map((list) => {
        list.isSelected = false;
        return list;
      });
      setSubNavList(data);
      setNavList(NavData);
    } else {
      const data = NavList.map((list) => {
        if (list.id === nodeValue.id) {
          list.isSelected = !nodeValue.isSelected;
        } else {
          list.isSelected = false;
        }
        return list;
      });
      const SubNavdata = SubNavList.map((list) => {
        list.isSelected = false;
        return list;
      });
      setNavList(data);
      setSubNavList(SubNavdata);
    }
  };
  /**
   * return JSX
   */
  return (
    <header>
      <NewMainHeaderContainer
        NavList={NavList}
        handleNodeClick={handleNodeClick}
        SubNavList={SubNavList}
        userDetails={userDetails}
      />
    </header>
  );
};
export default Header;
Header.propTypes = {
  NavItem: PropTypes.instanceOf(Array).isRequired,
  SubNavItem: PropTypes.instanceOf(Array).isRequired,
  userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
};

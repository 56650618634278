import {cloneDeep} from 'lodash';
import initialState from './InitialState';
import {
  SET_DASHBOARD_LOG_TAB,
  SET_DASHBOARD_BOOKINGS_TAB,
  SET_DASHBOARD_INFO_TAB,
  SET_DASHBOARD_IMGS_TAB,
  SET_DASHBOARD_ASSIGNMNETS_TAB,
  SET_DASHBOARD_MANAGE_TAB, SAVE_ISSUE_DATA, SAVE_ISSUE_IMG,
  CHANGE_TASK_NOTE, SET_IMG_PREVIEW, SET_TASK_CURRENT_FORM,
} from '../actions/ActionTypes';


const MobileDashboardTabsReducer = (state = initialState.mobileDashboardTabs, action) => {
  switch (action.type) {
    case SET_DASHBOARD_LOG_TAB: {
      const newVal = {...state.logCache, ...action.cache};
      return {
        ...state,
        logs: action.logs,
        logCache: newVal,
      };
    }
    case SET_DASHBOARD_BOOKINGS_TAB: {
      return {
        ...state,
        bookings: action.bookings,
        bookingCache: {...state.bookingCache, ...action.cache},
      };
    }
    case SET_DASHBOARD_INFO_TAB: {
      return {
        ...state,
        info: action.info,
        infoCache: {...state.infoCache, ...action.cache},
      };
    }
    case SET_DASHBOARD_IMGS_TAB: {
      return {
        ...state,
        imgs: action.imgs,
        imgsCache: {...state.imgsCache, ...action.cache},
      };
    }
    case SET_DASHBOARD_ASSIGNMNETS_TAB: {
      return {
        ...state,
        assignments: action.assignments,
        assignmentsCache: {...state.assignmentsCache, ...action.cache},
      };
    }
    case SET_DASHBOARD_MANAGE_TAB: {
      return {
        ...state,
        manage: action.manage,
        manageCache: {...state.manageCache, ...action.cache},
      };
    }
    case SAVE_ISSUE_DATA: {
      console.log("Saving issue data...")
      return {
        ...state,
        issue: {...action.data},
      };
    }
    case SAVE_ISSUE_IMG: {
      console.log("Saving issue image...")
      return {
        ...state,
        stateImgPreview: [...action.data],
      };
    }
    case CHANGE_TASK_NOTE: {
      return {
        ...state,
        taskNote: {...state.taskNote, [action.taskId]: action.data},
      };
    }
    case SET_IMG_PREVIEW: {
      const stateImgPreview = {...state.imgPreview};
      stateImgPreview[action.taskID] = cloneDeep(action.preview);
      return {
        ...state,
        imgPreview: {...stateImgPreview},
      };
    }
    case SET_TASK_CURRENT_FORM: {
      const statetaskCurrentForm = {...state.taskCurrentForm};
      statetaskCurrentForm[action.taskID] = cloneDeep(action.currentTaskFormData);
      return {
        ...state,
        taskCurrentForm: {...statetaskCurrentForm},
      };
    }
    default:
      return state;
  }
};
export default MobileDashboardTabsReducer;

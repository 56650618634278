const AUTHENTICATE = 'AUTHENTICATE';
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const SET_USER_PERMISSION = 'SET_USER_PERMISSION';
const SET_CURRENT_USER_LOCALE = 'ET_CURRENT_USER_LOCALE';
const SET_ALL_INTEGRATIONS_DETAILS = 'SET_ALL_INTEGRATIONS_DETAILS';
const UPDATE_INTEGRATIONS_DETAILS = 'UPDATE_INTEGRATIONS_DETAILS';
const DISCONNECT_QUICKBOOKS = 'DISCONNECT_QUICKBOOKS';
/* map properties */
const SET_ALL_PROPERTIES_DETAILS = 'SET_ALL_PROPERTIES_DETAILS';
const SET_ALL_CUSTOMERS_DETAILS = 'SET_ALL_CUSTOMERS_DETAILS';
/* All Filters */
const SET_ALL_PROPERTY_TAGS = 'SET_ALL_PROPERTY_TAGS';
const SET_ALL_REGIONS = 'SET_ALL_REGIONS';
const SET_ALL_OWNERS = 'SET_ALL_OWNERS';
const SET_ALL_STAFF_TAGS = 'SET_ALL_STAFF_TAGS';
const SET_ALL_DEPARTMENTS = 'SET_ALL_DEPARTMENTS';
const SET_ALL_PROPERTIES = 'SET_ALL_PROPERTIES';
const SET_ALL_STAFFS = 'SET_ALL_STAFFS';

/* Map payroll */
const SET_QBD_WAGES = 'SET_QBD_WAGES';
const SET_PAY_ROLL = 'SET_PAY_ROLL';

/* Map Staffs */
const SET_ALL_STAFFS_DETAILS = 'SET_ALL_STAFFS_DETAILS';
const SET_ALL_EMPLOYEES_DETAILS = 'SET_ALL_EMPLOYEES_DETAILS';
/* Map Task rules */
const SET_ALL_TASK_RULE_DETAILS = 'SET_ALL_TASK_RULE_DETAILS';
const SET_ALL_ITEMS_DETAILS = 'SET_ALL_ITEMS_DETAILS';

/* Billing approve */
const SET_ALL_BILLABLE_TASKS = 'SET_ALL_BILLABLE_TASKS';
const CHANGE_BILLABLE_STATUS = 'CHANGE_BILLABLE_STATUS';
const CHECK_BILLABLE_ITEM = 'CHECK_BILLABLE_ITEM';
const CHECK_ALL_BILLABLE_ITEM = 'CHECK_ALL_BILLABLE_ITEM';
const UNDO_SELECTED_BILLABLE_ITEMS = 'UNDO_SELECTED_BILLABLE_ITEMS';
const RESET_BILLING_APPROVE = 'RESET_BILLING_APPROVE';

/* Zapier tasks */
const SET_ALL_ZAPIER_TASKS = 'SET_ALL_ZAPIER_TASKS';
const CHECK_ALL_ZAPIER_ITEM = 'CHECK_ALL_ZAPIER_ITEM';
const CHECK_ZAPIER_ITEM = 'CHECK_ZAPIER_ITEM';
const RESET_ZAPIER_TASK_APPROVE = 'RESET_ZAPIER_TASK_APPROVE';
const UNDO_SELECTED_ZAPIER_TASK_ITEMS = 'UNDO_SELECTED_ZAPIER_TASK_ITEMS';
const CHANGE_ZAPIER_TASK_STATUS = 'CHANGE_ZAPIER_TASK_STATUS';

/* Time tracking Approve */
const SET_ALL_TIME_CLOCK = 'SET_ALL_TIME_CLOCK';
const CHANGE_TIME_STATUS = 'CHANGE_TIME_STATUS';
const UNDO_SELECTED_TIME_STATUS = 'UNDO_SELECTED_TIME_STATUS';
const RESET_TIME_CLOCK = 'RESET_TIME_CLOCK';

/* Data Sync Logs */
const SET_ALL_SYNC_LOGS = 'SET_ALL_SYNC_LOGS';
const SET_ALL_SYNC_LOGS_DETAILS = 'SET_ALL_SYNC_LOGS_DETAILS';
const DELETE_SYNC_LOGS_DETAILS = 'DELETE_SYNC_LOGS_DETAILS';


/* Mobile user validation */
const SET_CURRENT_MOBILE_USER = 'SET_CURRENT_MOBILE_USER';
const SET_CURRENT_MOBILE_TOKEN = 'SET_CURRENT_MOBILE_TOKEN';

/* Mobile dashboard task */
const SET_MOBILE_TASKS = 'SET_MOBILE_TASKS';
const START_TASK = 'START_TASK';
const START_TASK_CHANGE_AUTH = 'START_TASK_CHANGE_AUTH';
const CLOCK_IN_OUT = 'CLOCK_IN_OUT';
const ACCEPT_DECLINE_TASK = 'ACCEPT_DECLINE_TASK';
const COMPLETE_TASK = 'COMPLETE_TASK';
const DOWNLOAD_TASK = 'DOWNLOAD_TASK';

/* Mobile Dashboard tabs */
const SET_DASHBOARD_LOG_TAB = 'SET_DASHBOARD_LOG_TAB';
const SET_DASHBOARD_BOOKINGS_TAB = 'SET_DASHBOARD_BOOKINGS_TAB';
const SET_DASHBOARD_INFO_TAB = 'SET_DASHBOARD_INFO_TAB';
const SET_DASHBOARD_IMGS_TAB = 'SET_DASHBOARD_IMGS_TAB';
const SET_DASHBOARD_ASSIGNMNETS_TAB = 'SET_DASHBOARD_ASSIGNMNETS_TAB';
const SET_DASHBOARD_MANAGE_TAB = 'SET_DASHBOARD_MANAGE_TAB';
const CHANGE_DATE = 'CHANGE_DATE';
const CHANGE_TASK_NOTE = 'CHANGE_TASK_NOTE';
const SET_IMG_PREVIEW = 'SET_IMG_PREVIEW';
const SET_TASK_CURRENT_FORM = 'SET_TASK_CURRENT_FORM';

/* Unscheduled task */
const UNSCHEDULED_PROPERTIES = 'UNSCHEDULED_PROPERTIES';
const UNSCHEDULED_PROPERTY_DETAILS = 'UNSCHEDULED_PROPERTY_DETAILS';
const UNSCHEDULED_TASK_DETAILS = 'UNSCHEDULED_TASK_DETAILS';

/* translations */
const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
const SET_LOCALE_WORDS = 'SET_LOCALE_WORDS';
const SET_LOCALE_SINGLE_WORD = 'SET_LOCALE_SINGLE_WORD';

/* issue form */
const SET_CURRENT_ISSUE_USER = 'SET_CURRENT_ISSUE_USER';
const SAVE_ISSUE_IMAGE_BLOB = 'SAVE_ISSUE_IMAGE_BLOB';
const SAVE_TASK_IMAGE_BLOB = 'SAVE_TASK_IMAGE_BLOB';
const SUBMIT_ISSUE = 'SUBMIT_ISSUE';
const SAVE_ISSUE_DATA = 'SAVE_ISSUE_DATA';
const SAVE_ISSUE_IMG = 'SAVE_ISSUE_IMG';

export {
  AUTHENTICATE,
  SET_CURRENT_USER,
  SET_USER_PERMISSION,
  SET_CURRENT_USER_LOCALE,
  SET_ALL_INTEGRATIONS_DETAILS,
  UPDATE_INTEGRATIONS_DETAILS,
  SET_ALL_PROPERTIES_DETAILS,
  SET_ALL_CUSTOMERS_DETAILS,
  SET_ALL_PROPERTY_TAGS,
  SET_ALL_OWNERS,
  SET_ALL_REGIONS,
  SET_QBD_WAGES,
  SET_PAY_ROLL,
  SET_ALL_EMPLOYEES_DETAILS,
  SET_ALL_STAFFS_DETAILS,
  SET_ALL_DEPARTMENTS,
  SET_ALL_STAFF_TAGS,
  SET_ALL_ITEMS_DETAILS,
  SET_ALL_TASK_RULE_DETAILS,
  SET_ALL_BILLABLE_TASKS,
  CHANGE_BILLABLE_STATUS,
  CHECK_BILLABLE_ITEM,
  SET_ALL_PROPERTIES,
  CHECK_ALL_BILLABLE_ITEM,
  UNDO_SELECTED_BILLABLE_ITEMS,
  SET_ALL_STAFFS,
  SET_ALL_TIME_CLOCK,
  CHANGE_TIME_STATUS,
  UNDO_SELECTED_TIME_STATUS,
  RESET_TIME_CLOCK,
  RESET_BILLING_APPROVE,
  SET_ALL_SYNC_LOGS,
  SET_ALL_SYNC_LOGS_DETAILS,
  DELETE_SYNC_LOGS_DETAILS,
  DISCONNECT_QUICKBOOKS,
  SET_CURRENT_MOBILE_USER,
  SET_MOBILE_TASKS,
  SET_CURRENT_MOBILE_TOKEN,
  SET_DASHBOARD_LOG_TAB,
  SET_DASHBOARD_BOOKINGS_TAB,
  SET_DASHBOARD_INFO_TAB,
  SET_DASHBOARD_IMGS_TAB,
  SET_DASHBOARD_ASSIGNMNETS_TAB,
  SET_DASHBOARD_MANAGE_TAB,
  START_TASK,
  START_TASK_CHANGE_AUTH,
  CLOCK_IN_OUT,
  ACCEPT_DECLINE_TASK,
  CHANGE_DATE,
  COMPLETE_TASK,
  UNSCHEDULED_PROPERTIES,
  UNSCHEDULED_PROPERTY_DETAILS,
  UNSCHEDULED_TASK_DETAILS,
  DOWNLOAD_TASK,
  SET_TRANSLATIONS,
  SET_LOCALE_WORDS,
  SET_LOCALE_SINGLE_WORD,
  SET_CURRENT_ISSUE_USER,
  SUBMIT_ISSUE,
  SAVE_ISSUE_IMAGE_BLOB,
  SAVE_TASK_IMAGE_BLOB,
  SAVE_ISSUE_DATA,
  SAVE_ISSUE_IMG,
  CHANGE_TASK_NOTE,
  SET_IMG_PREVIEW,
  SET_TASK_CURRENT_FORM,
  SET_ALL_ZAPIER_TASKS,
  CHECK_ALL_ZAPIER_ITEM,
  CHECK_ZAPIER_ITEM,
  RESET_ZAPIER_TASK_APPROVE,
  UNDO_SELECTED_ZAPIER_TASK_ITEMS,
  CHANGE_ZAPIER_TASK_STATUS,
};

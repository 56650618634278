import React, { useState } from "react";
import { toast } from "react-toastify";
import { HandelError } from "./HandleError";

export const SWUpdate = () => {
  const [promptRefresh, setPromptRefresh] = useState(false);
  React.useEffect(() => {
    try {
      if ("serviceWorker" in navigator) {
        let refreshing;
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (refreshing) return;
          refreshing = true;
          window.location.reload();
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  React.useEffect(() => {
    function listenForWaitingServiceWorker(reg) {
      function awaitStateChange() {
        if (reg && reg.installing) {
          reg.installing.addEventListener("statechange", function () {
            if (this.state === "installed") setPromptRefresh(true);
          });
        } else {
          setPromptRefresh(false);
        }
      }
      if (!reg) return;
      if (reg.waiting) setPromptRefresh(true);
      if (reg.installing) awaitStateChange();
      if (reg && reg.addEventListener) {
        reg.addEventListener("updatefound", awaitStateChange);
      }
    }
    if ("serviceWorker" in navigator) {
      if (navigator.serviceWorker.getRegistration) {
        navigator.serviceWorker
          .getRegistration()
          .then((reg) => {
            if (reg) {
              listenForWaitingServiceWorker(reg);
            }
          })
          .catch((e) =>
            toast.error(<HandelError error={e} />, { autoClose: 2000 })
          );
      }
    }
  }, []);
  const handleRefreshForUpdate = () => {
    if (
      "serviceWorker" in navigator &&
      navigator.serviceWorker.getRegistration
    ) {
      navigator.serviceWorker
        .getRegistration()
        .then((reg) => {
          if (reg && reg.waiting) {
            reg.waiting.postMessage("skipWaiting");
          }
          setPromptRefresh(false);
        })
        .catch((err) => {
          toast.error(<HandelError error={err} />, { autoClose: 2000 });
          setPromptRefresh(false);
        });
    } else {
      setPromptRefresh(false);
    }
  };
  return (
    <span>
      {promptRefresh ? (
        <div
          className="bg-dark slide-down text-white pl-4 fixed-top"
          data-spy="affix-content"
          data-offset-top="0"
          style={{ backgroundColor: "black" }}
        >
          <button
            type="button"
            className="btn btn-link text-white"
            onClick={handleRefreshForUpdate}
          >
            <strong>
              {" "}
              New content available. Click here to refresh and fetch.
            </strong>
          </button>
        </div>
      ) : (
        ""
      )}
    </span>
  );
};

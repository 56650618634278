
import MobileDashboardService from "../../services/MobileDashboardService";
import {
  CLOCK_IN_OUT,
  SET_MOBILE_TASKS,
  START_TASK,
  START_TASK_CHANGE_AUTH,
  ACCEPT_DECLINE_TASK,
  CHANGE_DATE,
  DOWNLOAD_TASK,
} from "./ActionTypes";
import {changeTimeZone} from "../../utils/Client";

export const getALTasks = (tasks) => ({
  type: SET_MOBILE_TASKS,
  tasks,
});
export const downloadTaskAction = (task) => ({
  type: DOWNLOAD_TASK,
  task,
});

export const startOneTask = (task, startTask, manageTask) => ({
  type: START_TASK,
  task,
  startTask,
  manageTask,
});
export const acceptDeclineOneTask = (task, manageTask) => ({
  type: ACCEPT_DECLINE_TASK,
  task,
  manageTask,
});
export const changeStartTaskAuthData = (TimeClockTasks, clockedInDate) => ({
  type: START_TASK_CHANGE_AUTH,
  TimeClockTasks,
  clockedInDate,
});

export const changeHeaderAuthData = (ClockInOut) => ({
  type: CLOCK_IN_OUT,
  ClockInOut,
});

export const changeDate = (task) => ({
  type: CHANGE_DATE,
  task,
});

/**
 * call service to et all tasks
 * @param dispatch
 * @param token
 * @returns {Promise<boolean>}
 */
export const getTasks = async (dispatch, token, offset) => {
  try {
    const allTaskResponse = await MobileDashboardService.getAllTasks(token, offset);
    if (allTaskResponse.status === 200 || allTaskResponse.statusText === 'OK') {
      dispatch(getALTasks(allTaskResponse.data));
      return true;
    }
  } catch (e) {
    throw e.response;
  }
  return false;
};

export const manageTasks = async (taskRequest, dispatch, manageTask, Region) => {
  try {
    const startTaskResponse = await MobileDashboardService.starTask(taskRequest);
    if (startTaskResponse.status === 201 || startTaskResponse.statusText === 'OK') {
      dispatch(startOneTask(taskRequest, startTaskResponse.data.Started, manageTask));
      // check if the task is started change TimeClockTasks as 1 else 0
      dispatch(changeStartTaskAuthData(manageTask, startTaskResponse.data.Started));
      return true;
    }
  } catch (e) {
    if (!navigator.onLine) {
      const date = changeTimeZone(taskRequest.DateTime, Region, 'hh:mm A');
      dispatch(startOneTask(taskRequest, date, manageTask));
      dispatch(changeStartTaskAuthData(manageTask, date));
      return true;
    }
    throw e.response ? e.response.data : e;
  }
  return false;
};

export const clockInOut = async (requestData, dispatch) => {
  try {
    const clockInResponse = await MobileDashboardService.clockInOut(requestData);
    if (clockInResponse.status === 201 || clockInResponse.statusText === 'OK') {
      dispatch(changeHeaderAuthData(requestData));
      return true;
    }
  } catch (e) {
    if (!navigator.onLine) {
      dispatch(changeHeaderAuthData(requestData));
      return true;
    }
    throw e.response ? e.response.data : e;
  }
  return false;
};
export const acceptDecline = async (requestData, dispatch, manageTask) => {
  try {
    const acceptDeclineResponse = await MobileDashboardService.acceptDecline(requestData);
    if (acceptDeclineResponse.status === 201 || acceptDeclineResponse.statusText === 'OK') {
      dispatch(acceptDeclineOneTask(requestData, manageTask));
      return true;
    }
  } catch (e) {
   // offline use
    if (!navigator.onLine) {
      dispatch(acceptDeclineOneTask(requestData, manageTask));
      return true;
    }
    throw e.response ? e.response.data : e;
  }
  return false;
};

export const changeStartDate = async (requestData, dispatch) => {
  try {
    const changeStartDateResponse = await MobileDashboardService.changeStartDate(requestData);
    if (changeStartDateResponse.status === 201 || changeStartDateResponse.statusText === 'OK') {
      dispatch(changeDate({...requestData, SchedulingNote: changeStartDateResponse.data.Details ? changeStartDateResponse.data.Details.SchedulingNote : ' '}));
      // dispatch(acceptDeclineOneTask(requestData));
      return true;
    }
  } catch (e) {
    // offline use
    if (!navigator.onLine) {
      dispatch(changeDate(requestData));
      return true;
    }
    throw e.response ? e.response.data : e;
  }
  return false;
};

export const triggerStageEmail = async (requestData) => {
  try {
    const status = await MobileDashboardService.triggerEmail(requestData);
    if (status.status === 200 || status.statusText === 'OK') {
      return true;
    }
  } catch (e) {
    // offline use
    throw e.response ? e.response.data : e;
  }
  return false;
}
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from "../reducers";

export const ConfigureStore = () => (
  createStore(
    rootReducer,
    applyMiddleware(thunk),
  )
);

import {DISCONNECT_QUICKBOOKS, SET_ALL_INTEGRATIONS_DETAILS, UPDATE_INTEGRATIONS_DETAILS} from "./ActionTypes";

export const setAllIntegrations = (integrationsDetails) => ({
  type: SET_ALL_INTEGRATIONS_DETAILS,
  integrationsDetails,
});

export const updateIntegrationDetails = (data) => ({
  type: UPDATE_INTEGRATIONS_DETAILS,
  data,
});

/**
 * Disconncts the quickbooks
 * @returns {{type: *}}
 */
export const disconnectQBAction = (IntegrationID) => ({
  type: DISCONNECT_QUICKBOOKS,
  IntegrationID,
});

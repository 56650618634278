export const VRSErrorConst = {
  UNAUTHORISED_ACCESS: 402,
  TOKEN_EXPIRED: 401,
  TOKEN_DUPLICATE: 400,
  UNRECOGNISED_FIELD_TYPE: 422,
  RATE_LIMIT_EXCETED: 429,
  UNRECOGNISED_INTEGRATION_ID: 1001,
  UNPROCESSABLE_DATA: 1002,
  INACTIVE_USER: 1003,
  QB_PAYLOAD_ERROR: 1023,
  NO_CONTENT_CACHED: 2001,
};

import initialState from "./InitialState";
import {
  CHANGE_ZAPIER_TASK_STATUS,
  CHECK_ALL_ZAPIER_ITEM,
  CHECK_ZAPIER_ITEM, RESET_ZAPIER_TASK_APPROVE,
  SET_ALL_ZAPIER_TASKS,
  UNDO_SELECTED_ZAPIER_TASK_ITEMS,
} from "../actions/ActionTypes";
import {changeStatus, checkAllItems, checkItem, undoSelected} from "../../utils/Helper";

const ZapierTaskReducer = (state = initialState.zapierApprove, action) => {
  switch (action.type) {
    case SET_ALL_ZAPIER_TASKS: {
      return {
        ...state,
        zapierTasks: action.tasksDetails,
      };
    }
    case CHANGE_ZAPIER_TASK_STATUS: {
      let result = state.zapierTasks.Details;
      if (state.zapierTasks.Details) {
        result = changeStatus(state.zapierTasks.Details, action.taskItem, action.status, 'TaskID');
      }
      const zapierTask = {...state.zapierTasks};
      zapierTask.Details = result;
      return {
        ...state,
        zapierTasks: zapierTask,
      };
    }
    case CHECK_ALL_ZAPIER_ITEM: {
      let result = state.zapierTasks.Details;
      if (state.zapierTasks.Details) {
        result = checkAllItems(state.zapierTasks.Details, action.checkAll);
      }
      const zapierTask = {...state.zapierTasks};
      zapierTask.Details = result;
      return {
        ...state,
        zapierTasks: zapierTask,
      };
    }
    case UNDO_SELECTED_ZAPIER_TASK_ITEMS: {
      let result = state.zapierTasks.Details;
      if (state.zapierTasks.Details) {
        result = undoSelected(state.zapierTasks.Details);
      }
      const zapierTask = {...state.zapierTasks};
      zapierTask.Details = result;
      return {
        ...state,
        zapierTasks: zapierTask,
      };
    }
    case RESET_ZAPIER_TASK_APPROVE: {
      let result = state.zapierTasks.Details;
      if (state.zapierTasks.Details) {
        result = state.zapierTasks.Details.map(({prevStatus, checked, ...item}) => item);
      }
      const zapierTask = {...state.zapierTasks};
      zapierTask.Details = result;
      return {
        ...state,
        zapierTasks: zapierTask,
      };
    }
    case CHECK_ZAPIER_ITEM: {
      let result = state.zapierTasks.Details;
      if (state.zapierTasks.Details) {
        result = checkItem(state.zapierTasks.Details, action.taskItem, 'TaskID');
      }
      const zapierTask = {...state.zapierTasks};
      zapierTask.Details = result;
      return {
        ...state,
        zapierTasks: zapierTask,
      };
    }
    default:
      return state;
  }
};
export default ZapierTaskReducer;

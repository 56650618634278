import initialState from './InitialState';
import {
  CHANGE_TIME_STATUS,
  SET_ALL_TIME_CLOCK,
  UNDO_SELECTED_TIME_STATUS,
  RESET_TIME_CLOCK,
} from '../actions/ActionTypes';
import {changeStatus, undoSelected} from "../../utils/Helper";

const TimeTrackingReducer = (state = initialState.timeTracking, action) => {
  switch (action.type) {
    case SET_ALL_TIME_CLOCK: {
      return {
        ...state,
        timeClock: action.timeDetails,
      };
    }
    case CHANGE_TIME_STATUS: {
      let result = state.timeClock.Details;
      if (state.timeClock.Details) {
        if (action.item.IntegrationQBDTimeTrackingRecordID) {
          result = changeStatus(state.timeClock.Details, action.item, action.status, 'IntegrationQBDTimeTrackingRecordID');
        } else if (("TimeClockTasksID" in action.item)) {
          result = changeStatus(state.timeClock.Details, action.item, action.status, 'TimeClockTasksID');
        } else {
          result = changeStatus(state.timeClock.Details, action.item, action.status, 'TimeClockDaysID');
        }
      }
      const timeClock = {...state.timeClock};
      timeClock.Details = result;
      return {
        ...state,
        timeClock,
      };
    }
    case UNDO_SELECTED_TIME_STATUS: {
      let result = state.timeClock.Details;
      if (state.timeClock.Details) {
        result = undoSelected(state.timeClock.Details);
      }
      const timeClock = {...state.timeClock};
      timeClock.Details = result;
      return {
        ...state,
        timeClock,
      };
    }
    case RESET_TIME_CLOCK: {
      let result = state.timeClock.Details;
      if (state.timeClock.Details) {
        result = state.timeClock.Details.map(({prevStatus, checked, ...item}) => item);
      }
      const timeClock = {...state.timeClock};
      timeClock.Details = result;
      return {
        ...state,
        timeClock,
      };
    }
    default:
      return state;
  }
};
export default TimeTrackingReducer;

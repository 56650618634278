export const VRSConst = {
  url: 'https://phpapi.prod.vrscheduler.com',
  version: "v1",
  suffix: "vrs",
  refreshTime: 3360, // in seconds
  refreshMobileTime: 3360,
  checkVersionTime: 300,
  logoutTime: 540,
  getUrl: () => `${VRSConst.url}/${VRSConst.version}/${VRSConst.suffix}`,
  module: {
    ALLOW_MASTER: "AllowAlways",
    ALLOW_ADMIN_ACCESS: "AllowAdminAccess",
    ALLOW_MAANGE: "AllowManage",
    ALLOW_CONNECTED_STRIPE_ID: "ConnectedStripeAccountID",
    ALLOW_USE_QUICKBOOKS: "UseQuickbooks",
    ALLOW_REPORTS: "AllowReports",
    ALLOW_SETUP_ACCESS: "AllowSetupAccess",
    ALLOW_ACCOUNT_ACCESS: "AllowAccountAccess",
    ALLOW_ISSUE_ACCESS: "AllowIssuesAccess",
    ALLOW_QUICK_REPORTS: "AllowQuickReports",
    ALLOW_SCHEDULE_ACCESS: "AllowScheduleAccess",
    ALLOW_MASTER_CALENDER: "AllowMasterCalendar",
    ALLOW_TRACKING: "AllowTracking",
    ALLOW_TIME_TRACKING: "TimeTracking",
    ALLOW_REGION_GROUP: "RegionGroup",
    ALLOW_PROPERTY_GROUP: "PropertyGroup",
    ALLOW_PIECE_PAY: "PiecePay",
    ALLOW_I_CAL_ADD_ON: "ICalAddOn",
    ALLOW_EDIT_BOOKINGS: "AllowEditBookings",
    ALLOW_TRACK_LABOR_MATERIAL: "TrackLaborOrMaterials",
    ALLOW_INTEGRATION_COMPANY_ID: "IntegrationCompanyID",
    ALLOW_ALERT_ON_DAMAGE: "AlertOnDamage",
    ALLOW_ALERT_ON_MAINTAINACE: "AlertOnMaintainace",
    ALLOW_HAS_NOTE: "HasNote",
    ALLOW_HAS_PROPERTY: "HasProperty",
  },
  cookieName: 'CFWJWT',
  menuRedirectedURL: 'https://teams.operto.com',
  menuHelpRedurectedURL: "https://help.vrscheduler.com/",
  reactAppVersion: '3.0.202302032331',
  integrationURL: 'https://teams-app.operto.com/integrations',
  currentURL: 'https://teams-app.operto.com',
  awsImgURL: "https://vrscheduler.s3.us-west-2.amazonaws.com/media",
  awsRegion: 'us-west-2',
  awsPoolId: 'us-west-2:0dedf037-a9d8-4569-b267-6b1d4112411a',
  awsApiVersion: '2006-03-01',
  mainPage: "/integrations",
  dashboardPage: "/servicer/dashboard",
  perPageLimit: 20,
  startDate: "1970-01-01",
  publicUrl:'https://teams-app.operto.com',
  bulkStatus: {
    Excluded: 0,
    Approved: 1,
    New: 2,
  },
  filter: {
    STAFF_FILTER: "MapStaffs",
    TASK_RULE_FILTER: "MapTaskRule",
  },
  logStatus: {
    Success: 1,
    Failed: 0,
  },
  BILLING_FILTER: "BILLING_FILTER",
  TIME_TRACKING_FILTER: "TIME_TRACKING_FILTER",
  ZAPIER_FILTER: "ZAPIER_FILTER",
  IntegrationType: {
    QUICKBOOKS_ENTERPRISE: 0,
    QUICKBOOKS_PRO: 1,
    QUICKBOOKS_ONLINE: 2,
  },
  TimeTrackingType: {
    TIME_TRACK_BY_DAYS: 0,
    TIME_TRACK_BY_TASKS: 1,
  },
  toastList: new Set(),
  MAX_TOAST: 1,
  MAX_MULTIPLE_IMAGE_OFFLINE: 10,
  zapierStatus: {
    Excluded: 0,
    Approved: 1,
    New: 2,
  },
  IntegrationName: {
    QUICKBOOKS: "QuickBooks",
    ZAPIER: "Zapier",
  },
  tableSortType: {
    ASC: "asc",
    DESC: "desc",
  },
};

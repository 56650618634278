import jwt from "jsonwebtoken";
import cookie from "react-cookies";
import { VRSConst } from "../app/Constants";


// Token
export const setToken = async (token, name = VRSConst.cookieName) => {
  await cookie.save(name, token, { path: "/" });
};

// Get the token
const getToken = (name = VRSConst.cookieName) => {
  // console.log("Getting Token ..." + name)
  const loadedFromCookie = cookie.load(name);
  // console.log(loadedFromCookie);
  return loadedFromCookie;
}
// delete the current token
const deleteToken = () => cookie.remove(VRSConst.cookieName);
/**
 * Set the current user detais in cookie
 * @param details
 * @param name
 */
const setUserDetails = async (details, name) => {
  await cookie.save(name, details, { path: "/" });
};

const setPermission = async (permissions, name = "permission") => {
  try {
    await cookie.save(name, permissions, { path: "/" });
  } catch (err) {
    console.log(err);
  }
};

const setLoggedInStaff = (loggedInStaffId, loggedInServicerPwd) =>
  cookie.save(
    "loggedInStaff",
    { loggedInStaffId, loggedInServicerPwd },
    { path: "/" }
  );

// get the permission
const getPermission = (name = "permission") => cookie.load(name);

const getLoggedInStaff = () => cookie.load("loggedInStaff");

const deletePermission = () => cookie.remove("permission");

// clear all token
const clearToken = () =>
  new Promise((resolve, reject) => {
    try {
      cookie.remove(VRSConst.cookieName);
      cookie.remove("permission");
      cookie.remove("u_details");
      cookie.remove("loggedInStaff");
      resolve(true);
    } catch (err) {
      reject(false);
    }
  });

const clearMobileToken = () =>
  new Promise((resolve, reject) => {
    try {
      cookie.remove("MJWT", { path: "/", domain: ".vrscheduler.com" });
      cookie.remove("MJWT");
      cookie.remove("Mdetails", { path: "/", domain: ".vrscheduler.com" });
      cookie.remove("Mdetails");
      cookie.remove("Mpermission", { path: "/", domain: ".vrscheduler.com" });
      cookie.remove("Mpermission");
      resolve(true);
    } catch (err) {
      reject(false);
    }
  });

const clearIssueToken = () =>
  new Promise((resolve, reject) => {
    try {
      cookie.remove("IJWT", { path: "/", domain: ".vrscheduler.com" });
      cookie.remove("Idetails", { path: "/", domain: ".vrscheduler.com" });
      resolve(true);
    } catch (err) {
      reject(false);
    }
  });


const getUserFromTokenWithName = (name) => jwt.decode(cookie.load(name));
const getUserFromToken = () => jwt.decode(cookie.load(VRSConst.cookieName));

export {
  getToken,
  deleteToken,
  setUserDetails,
  setPermission,
  getPermission,
  deletePermission,
  clearToken,
  getUserFromToken,
  getUserFromTokenWithName,
  setLoggedInStaff,
  getLoggedInStaff,
  clearMobileToken,
  clearIssueToken,
};

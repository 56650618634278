import {axiosInstance, convertJsonBase64} from '../utils/Client';

export default class IntegrationService {
  /**
   * call api to get all the available and installed integrations
   * @returns {Promise<AxiosResponse<T>>}
   */
  static getAllIntegrations = async () => {
    let Response = null;
    Response = await axiosInstance.get('/integrations');
    return Response;
  };

  /**
   * Call register integration API
   * @param requestData
   * @returns {Promise<AxiosResponse<T>>}
   */
  static installIntegrations = async (requestData) => {
    let Response = null;
    Response = await axiosInstance.put('/qwc/register', requestData);
    return Response;
  };

  /**
   * Call to change installed integration settings
   * @param requestData
   * @returns {Promise<AxiosResponse<T>>}
   */
  static changeIntegrationDetails = async (requestData) => {
    let Response = null;
    Response = await axiosInstance.put('/qwc/register', requestData);
    return Response;
  };

  /**
   * API call to download the file
   * @param requestData
   * @returns {Promise<AxiosResponse<T>>}
   */
  static downloadIntegrations = async (requestData) => {
    const request = {IntegrationID: requestData};
    let Response = null;
    Response = await axiosInstance.get(`qwc/file/export?data=${convertJsonBase64(request)}`, {responseType: 'blob'});
    return Response;
  };

  /**
   *
   * @returns {Promise<boolean>}
   */
  static disconnectQB = async (requestData) => {
    const req = {IntegrationID: requestData};
    let Response = null;
    Response = await axiosInstance.put('/qbddisconnect', req);
    return Response;
  };

  /**
   * connect to quickbooks online
   * @returns {Promise<AxiosResponse<T>>}
   */
  static connectQBOnline = async () => {
    let Response = null;
    Response = await axiosInstance.get('/qbo');
    return Response;
  };

  /**
   * Sync the resources from quickbooks to server
   * @returns {Promise<AxiosResponse<T>>}
   * @constructor
   */
  static QBOSyncResources = async (requestData) => {
    const request = {IntegrationID: requestData};
    let Response = null;
    Response = await axiosInstance.get(`/qbo/syncresources?data=${convertJsonBase64(request)}`);
    return Response;
  };

  /**
   * Send data to sync in Quickbooks
   * @param requestData
   * @returns {Promise<AxiosResponse<T>>}
   */
  static syncToQBO = async (requestData) => {
    const request = {IntegrationID: requestData};
    let Response = null;
    Response = await axiosInstance.get(`qbo/syncbilling?data=${convertJsonBase64(request)}`);
    return Response;
  };

  /**
   * Send data to sync in Quickbooks
   * @param requestData
   * @returns {Promise<AxiosResponse<T>>}
   */
  static syncTimeTrackingToQBO = async (requestData) => {
    const request = {IntegrationID: requestData};
    let Response = null;
    Response = await axiosInstance.get(`qbo/synctimetracking?data=${convertJsonBase64(request)}`);
    return Response;
  };

  static installZapierService = async (requestData) => {
    let Response = null;
    Response = await axiosInstance.put(`zapier/register`, requestData);
    return Response;
  }
}

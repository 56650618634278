import React from 'react';
import App from "../App";

const IntegrationsContainer = React.lazy(() => import('../../container/IntegrationsContainer'));
const AboutQBContainer = React.lazy(() => import('../../component/integrations/view/AboutQB'));
const MapPropertiesContainer = React.lazy(() => import('../../container/MapPropertiesContainer'));
const MapPayRoll = React.lazy(() => import('../../container/MapPayrollContainer'));
const MapStaffsContainer = React.lazy(() => import('../../container/MapStaffsContainer'));
const MapTaskRuleContainer = React.lazy(() => import('../../container/MapTaskRuleContainer'));
const BillingApproveContainer = React.lazy(() => import('../../container/BillingApproveContainer'));
const TimeTrackingApprovalContainer = React.lazy(() => import('../../container/TimeTrackingApprovalContainer'));
const SyncLogContainer = React.lazy(() => import('../../container/SyncLogContainer'));
const SyncLogDetailsContainer = React.lazy(() => import('../../container/SyncLogDetailsContainer'));
const QBOnlineContainer = React.lazy(() => import('../../container/QBOnlineContainer'));
const MobileAppRoute = React.lazy(() => import('../MobileApp'));
const MobileTaskContainer = React.lazy(() => import('../../container/MobileTaskContainer'));
const TranslationRoute = React.lazy(() => import('../TranslationApp'));
const TranslatePage = React.lazy(() => import('../../container/translations/TranslatePageContainer'));
const LocaleDetails = React.lazy(() => import('../../container/translations/LocaleDetailsContainer'));
const IssueAppRoute = React.lazy(() => import('../IssueApp'));
// const BookingCalenderContainer = React.lazy(() => import('../../container/calender/BookingCalenderContainer'));
//zapier
const TaskApproveContainer = React.lazy(() => import('../../container/ApproveZapierTask'));
export {
  App,
  IntegrationsContainer,
  AboutQBContainer,
  MapPropertiesContainer,
  MapPayRoll,
  MapStaffsContainer,
  MapTaskRuleContainer,
  BillingApproveContainer,
  TimeTrackingApprovalContainer,
  SyncLogContainer,
  SyncLogDetailsContainer,
  MobileAppRoute,
  QBOnlineContainer,
  TranslationRoute,
  TranslatePage,
  LocaleDetails,
  IssueAppRoute,
  MobileTaskContainer,
  TaskApproveContainer,
};
